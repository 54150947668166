<template>
  <div>
    <top-header v-bind:title="segment | defaultPropertyValue('title', $t('all'))">
      <v-menu slot="menu"
              v-if="hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-monitor-multiple</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item exact
                       v-bind:to="{ name: 'home' }">
            <v-list-item-title>{{ $t('all') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-bind:key="segment.id"
                       v-bind:to="{ name: 'segment-monitor', params: { id: segment.id } }"
                       v-for="segment in segments">
            <v-list-item-title>{{ segment.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <segment-display v-bind:segment="segment"
                       v-bind:shelves="shelvesForSegment" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import SegmentDisplay from '@/components/SegmentDisplay'

  export default {
    beforeRouteEnter(to, from, next) {
      const id = to.params.id

      next(vm => {
        vm.segmentId = id
      })
    },
    beforeRouteUpdate(to, from, next) {
      const id = to.params.id

      this.segmentId = id

      next()
    },
    components: {
      SegmentDisplay,
      TopHeader
    },
    computed: {
      segment() {
        return this.getSegment(this.segmentId)
      },
      shelvesForSegment() {
        let shelves = this.shelves

        if (this.segmentId != null) {
          shelves = this.shelves.filter(s => s.segmentId === this.segmentId)
        }

        return shelves.sort((a, b) => new Date(a.date) - new Date(b.date))
      },
      ...mapGetters({
        getSegment: 'base/segment',
        hasRole: 'auth/hasRole',
        segments: 'base/segments',
        shelves: 'base/shelves'
      })
    },
    async created() {
      this.segmentId = this.$route.params.id

      await this.loadSegments()
      await this.loadShelves({ forDisplay: true })

      this.interval = setInterval(async () => {
        await this.loadShelves({ forDisplay: true })
      }, 120000)
    },
    data: () => ({
      segmentId: null,
      interval: null
    }),
    methods: {
      ...mapActions({
        loadSegments: 'base/loadSegments',
        loadShelves: 'base/loadShelves'
      })
    },
    destroyed() {
      clearInterval(this.interval)
    },
    name: 'SegmentMonitor'
  }
</script>

<i18n>
  {
    "de": {
      "all": "Alle"
    },
    "en": {
      "all": "All"
    }
  }
</i18n>