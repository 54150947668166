<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisSegmentIrrevocably')"
                    v-bind:title="$t('deleteSegment')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="segment | defaultPropertyValue('title', $t('segment'))">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-segments' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                     v-bind:label="$t('save')"
                     v-on:click="updateSegment" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                       v-on:click="deleteSegment">
            <v-list-item-title v-bind:class="hasRole('SagasserSortScanAdministrator') ? 'deep-orange--text' : null">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <segment-details-card ref="segmentDetailsCard"
                                v-bind:segment="segment"
                                v-if="segment != null" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import SegmentDetailsCard from '@/components/administration/SegmentDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = to.params.id

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      HeaderButton,
      SegmentDetailsCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        getSegment: 'base/segment',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      const id = this.$route.params.id

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      loading: false,
      segment: null,
      validationSnackbar: false
    }),
    methods: {
      async deleteSegment() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteSegment({ id: this.segment.id })

          this.loading = false

          this.$router.push({ name: 'administration-segments' })
        })
      },
      async get(id) {
        this.loading = true

        await this.loadSegments()

        this.loading = false

        this.segment = Object.assign({}, this.getSegment(id))
      },
      async updateSegment() {
        if (!this.$refs.segmentDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateSegment({ segment: this.segment })

        this.loading = false

        this.$router.push({ name: 'administration-segments' })
      },
      ...mapActions({
        doDeleteSegment: 'base/deleteSegment',
        doUpdateSegment: 'base/updateSegment',
        loadSegments: 'base/loadSegments'
      })
    },
    name: 'Segment'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisSegmentIrrevocably": "Sind Sie sicher, dass Sie dieses Segment unwiderruflich löschen möchten?",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteSegment": "Segment löschen",
      "save": "Speichern",
      "segment": "Segment",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisSegmentIrrevocably": "Are you sure you want to delete this segment irrevocably?",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteSegment": "Delete segment",
      "save": "Save",
      "segment": "Segment",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>