export default class SegmentService {
    constructor(baseUri) {
      this.baseUri = baseUri
    }

    async create(segment, options) {
      const response = await fetch(`${this.baseUri}/segment`, {
        body: JSON.stringify(segment),
        headers: {
          'Authorization': `Bearer ${options.accessToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })

      return await response.json()
    }

    async delete(id, options) {
      const response = await fetch(`${this.baseUri}/segment/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        },
        method: 'DELETE'
      })

      return response.ok
    }

    async find(id, options) {
      try {
        const response = await fetch(`${this.baseUri}/segment/${id}`, {
          headers: {
            'Authorization': `Bearer ${options.accessToken}`
          }
        })

        return await response.json()
      } catch (e) {
        return null
      }
    }

    async getAll(options) {
      const response = await fetch(`${this.baseUri}/segment`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    }

    async update(id, segment, options) {
      const response = await fetch(`${this.baseUri}/segment/${id}`, {
        body: JSON.stringify(segment),
        headers: {
          'Authorization': `Bearer ${options.accessToken}`,
          'Content-Type': 'application/json'
        },
        method: 'PUT'
      })

      return response.ok
    }
  }