export default class MaintenanceServie {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async reboot(options) {
    const response = await fetch(`${this.baseUri}/maintenance/reboot`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}