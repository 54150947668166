<template>
  <div>
    <top-header v-bind:title="$t('products')">
      <header-button icon="mdi-database-import"
                     right
                     slot="right-button"
                     v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                     v-bind:label="$t('import')" />
    </top-header>
    <v-container>
      <product-table-card v-bind:loading="loading"
                          v-bind:products="products" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ProductTableCard from '@/components/administration/ProductTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      ProductTableCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        hasRole: 'auth/hasRole',
        products: 'base/products'
      })
    },
    async created() {
      this.loading = true

      await this.loadProducts()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadProducts: 'base/loadProducts'
      })
    },
    name: 'Products'
  }
</script>

<i18n>
  {
    "de": {
      "import": "Importieren",
      "products": "Produkte"
    },
    "en": {
      "import": "Import",
      "products": "Products"
    }
  }
</i18n>