<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field filled
                            hide-details="auto"
                            v-bind:disabled="!editableId"
                            v-bind:label="$t('id')"
                            v-bind:rules="[ rules.required ]"
                            v-model="segment.id" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field filled
                            hide-details="auto"
                            v-bind:label="$t('title')"
                            v-bind:rules="[ rules.required ]"
                            v-model="segment.title" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    name: 'SegmentDetailsCard',
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    props: {
      editableId: {
        type: Boolean,
        default: false
      },
      segment: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details",
      "id": "Nr.",
      "title": "Bezeichnung"
    },
    "en": {
      "details": "Details",
      "id": "ID",
      "title": "Title"
    }
  }
</i18n>