export default {
  actions: {},
  getters: {
    historyEventTypeLabel: state => name => state.historyEventTypes.find(h => h.value === name)?.label ?? 'global.unknown'
  },
  mutations: {},
  namespaced: true,
  state: {
    historyEventTypes: [
      {
        label: 'global.notFound',
        value: 'not-found'
      },
      {
        label: 'global.productScanned',
        value: 'product-scanned'
      },
      {
        label: 'global.shelfEmptied',
        value: 'shelf-emptied'
      },
      {
        label: 'global.shelfFilled',
        value: 'shelf-filled'
      }
    ]
  }
}