export default class ShelfService {
    constructor(baseUri) {
      this.baseUri = baseUri
    }

    async create(shelf, options) {
      const response = await fetch(`${this.baseUri}/shelf`, {
        body: JSON.stringify(shelf),
        headers: {
          'Authorization': `Bearer ${options.accessToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })

      return await response.json()
    }

    async delete(id, options) {
      const response = await fetch(`${this.baseUri}/shelf/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        },
        method: 'DELETE'
      })

      return response.ok
    }

    async find(id, options) {
      try {
        const response = await fetch(`${this.baseUri}/shelf/${id}`, {
          headers: {
            'Authorization': `Bearer ${options.accessToken}`
          }
        })

        return await response.json()
      } catch (e) {
        return null
      }
    }

    async getAll(options) {
      const response = await fetch(`${this.baseUri}/shelf?forDisplay=${options.forDisplay}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    }

    async update(id, shelf, options) {
      const response = await fetch(`${this.baseUri}/shelf/${id}`, {
        body: JSON.stringify(shelf),
        headers: {
          'Authorization': `Bearer ${options.accessToken}`,
          'Content-Type': 'application/json'
        },
        method: 'PUT'
      })

      return response.ok
    }
  }