<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('packagingGroups') }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon
               v-on:click="addPackagingGroupFromLastEvent">
          <v-icon>mdi-barcode-scan</v-icon>
        </v-btn>
      </v-toolbar>
      <v-autocomplete auto-select-first
                      flat
                      hide-details="auto"
                      prepend-inner-icon="mdi-plus"
                      solo
                      v-bind:items="packagingGroupItems"
                      v-bind:label="$t('addPackagingGroup')"
                      v-model="newPackagingGroup"
                      v-on:change="addPackagingGroup" />
      <v-divider v-if="shelfPackagingGroups.length > 0" />
      <v-list-item two-line
                   v-bind:key="index"
                   v-for="(shelfPackagingGroup, index) in shelfPackagingGroups">
        <v-list-item-content>
          <v-list-item-title>{{ shelfPackagingGroup.brand }}</v-list-item-title>
          <v-list-item-subtitle>{{ shelfPackagingGroup.unit }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="deep-orange"
                    v-on:click="deletePackagingGroup(shelfPackagingGroup)">
              mdi-close-circle
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      packagingGroupItems() {
        return this.packagingGroups.filter(p => !this.shelfPackagingGroups.some(s => p.brand === s.brand && p.unit === s.unit))
          .map(p => ({
            text: `${p.brand} ${p.unit}`,
            value: p
          }))
      },
      ...mapGetters({
        lastHistoryEvent: 'base/lastHistoryEvent',
        packagingGroups: 'base/packagingGroups',
        products: 'base/products'
      })
    },
    data: () => ({
      newPackagingGroup: null
    }),
    name: 'ShelfPackagingGroupsCard',
    methods: {
      addPackagingGroup(packagingGroup) {
        if (packagingGroup == null) {
          return
        }

        this.$emit('add-packaging-group', packagingGroup)
      },
      async addPackagingGroupFromLastEvent() {
        await this.loadHistoryEvents()

        let europeanArticleNumber = this.lastHistoryEvent?.data

        if (europeanArticleNumber == null) {
          return
        }

        let product = this.products?.find(p => p.europeanArticleNumber === europeanArticleNumber)

        if (product == null) {
          return
        }

        this.$emit('add-packaging-group', {
          brand: product.packagingGroupBrand,
          unit: product.packagingGroupUnit
        })
      },
      deletePackagingGroup(packagingGroup) {
        this.$emit('delete-packaging-group', packagingGroup)
      },
      validate() {
        return this.$refs.form.validate()
      },
      ...mapActions({
        loadHistoryEvents: 'base/loadHistoryEvents'
      })
    },
    props: {
      shelfPackagingGroups: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "addPackagingGroup": "Verpackungsgruppe hinzufügen",
      "assignments": "Zuweisungen",
      "packagingGroups": "Verpackungsgruppen"
    },
    "en": {
      "addPackagingGroup": "Add packaging group",
      "assignments": "Assignments",
      "packagingGroups": "Packaging groups"
    }
  }
</i18n>