<template>
  <div>
    <v-card light
            v-bind:class="isOld(shelf.modificationDate) ? 'pulse' : null"
            v-bind:color="isOld(shelf.modificationDate) ? 'primary' : null">
      <v-card-text>
        <p v-bind:class="titleClasses(shelf)">{{ shelf.title }}</p>
        <p v-bind:class="textClasses(shelf)"
           v-if="!small">
          {{ shelf.modificationDate | fromNow }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    methods: {
      isOld(date) {
        return moment.duration(moment().diff(moment(date))).asMinutes() > 5
      },
      textClasses(shelf) {
        return {
          'accent--text': !this.isOld(shelf.modificationDate),
          'mb-0': true,
          'text-center': true,
          'text-h6': true,
          'white--text': this.isOld(shelf.modificationDate)
        }
      },
      titleClasses(shelf) {
        return {
          'primary--text': !this.isOld(shelf.modificationDate),
          'font-weight-black': true,
          'high-font-size': !this.small,
          'mb-0': true,
          'text-h1': !this.small,
          'text-h4': this.small,
          'text-center': true,
          'white--text': this.isOld(shelf.modificationDate)
        }
      }
    },
    name: 'ShelfCard',
    props: {
      shelf: Object,
      small: Boolean
    }
  }
</script>

<style scoped>
  @keyframes pulse {
    0% {
      opacity: 0.5;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      opacity: 1;
      box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0);
    }

    100% {
      opacity: 0.5;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  .high-font-size {
    font-size: 8rem !important;
    line-height: 8rem;
  }

  .pulse {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    opacity: 1;
    animation: pulse 2s infinite;
  }
</style>