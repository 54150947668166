import Vue from 'vue'
import HistoryEventService from '@/api/common/history-event-service'
import MaintenanceService from '@/api/common/maintenance-service'
import PackagingGroupService from '@/api/common/packaging-group-service'
import ProductService from '@/api/common/product-service'
import SegmentService from '@/api/common/segment-service'
import ShelfPackagingGroupService from '@/api/common/shelf-packaging-group-service'
import ShelfService from '@/api/common/shelf-service'

import settings from '@/settings'

const historyEventService = new HistoryEventService(settings.apiUri)
const maintenanceService = new MaintenanceService(settings.apiUri)
const packagingGroupService = new PackagingGroupService(settings.apiUri)
const productService = new ProductService(settings.apiUri)
const segmentService = new SegmentService(settings.apiUri)
const shelfPackagingGroupService = new ShelfPackagingGroupService(settings.apiUri)
const shelfService = new ShelfService(settings.apiUri)

export default {
  actions: {
    // historyEvents
    // =============
    async loadHistoryEvents({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const historyEvents = await historyEventService.getAll({ accessToken })

      commit('setHistoryEvents', { historyEvents })
    },

    // maintenance
    // ===========
    async maintenanceReboot({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      await maintenanceService.reboot({ accessToken })
    },

    // packagingGroups
    // ===============
    async loadPackagingGroups({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const packagingGroups = await packagingGroupService.getAll({ accessToken })

      commit('setPackagingGroups', { packagingGroups })
    },

    // products
    // ========
    async loadProducts({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const products = await productService.getAll({ accessToken })

      commit('setProducts', { products })
    },

    // segments
    // ========
    async createSegment({ commit, rootGetters }, { segment }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await segmentService.create(segment, { accessToken })

      commit('createSegment', { segment: result })
    },
    async deleteSegment({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await segmentService.delete(id, { accessToken })

      if (result) {
        commit('deleteSegment', { id })
      }
    },
    async loadSegments({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const segments = await segmentService.getAll({ accessToken })

      commit('setSegments', { segments })
    },
    async updateSegment({ commit, rootGetters }, { segment }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await segmentService.update(segment.id, segment, { accessToken })

      if (result) {
        commit('updateSegment', { segment })
      }
    },

    // shelves
    // =======
    async createShelf({ commit, rootGetters }, { shelf }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await shelfService.create(shelf, { accessToken })

      commit('createShelf', { shelf: result })
    },
    async deleteShelf({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await shelfService.delete(id, { accessToken })

      if (result) {
        commit('deleteShelf', { id })
      }
    },
    async loadShelves({ commit, rootGetters }, { forDisplay }) {
      const accessToken = rootGetters['auth/accessToken']

      const shelves = await shelfService.getAll({ accessToken, forDisplay })

      commit('setShelves', { shelves })
    },
    async updateShelf({ commit, rootGetters }, { shelf }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await shelfService.update(shelf.id, shelf, { accessToken })

      if (result) {
        commit('updateShelf', { shelf })
      }
    },

    // shelfPackagingGroups
    // ====================
    async createShelfPackagingGroup({ commit, rootGetters }, { shelfPackagingGroup }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await shelfPackagingGroupService.create(shelfPackagingGroup, { accessToken })

      commit('createShelfPackagingGroup', { shelfPackagingGroup: result })
    },
    async deleteShelfPackagingGroup({ commit, rootGetters }, { shelfId, packagingGroupBrand, packagingGroupUnit }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await shelfPackagingGroupService.delete(shelfId, packagingGroupBrand, packagingGroupUnit, { accessToken })

      if (result) {
        commit('deleteShelfPackagingGroup', { shelfId, packagingGroupBrand, packagingGroupUnit })
      }
    },
    async loadShelfPackagingGroups({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const shelfPackagingGroups = await shelfPackagingGroupService.getAll({ accessToken })

      commit('setShelfPackagingGroups', { shelfPackagingGroups })
    },
    async updateShelfPackagingGroup({ commit, rootGetters }, { shelfPackagingGroup }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await shelfPackagingGroupService.update(shelfPackagingGroup.shelfId, shelfPackagingGroup.packagingGroupBrand, shelfPackagingGroup.packagingGroupUnit, shelfPackagingGroup, { accessToken })

      if (result) {
        commit('updateShelfPackagingGroup', { shelfPackagingGroup })
      }
    }
  },
  getters: {
    historyEvent: state => id => state.historyEvents.find(h => h.id === id),
    historyEvents: state => state.historyEvents,
    lastHistoryEvent: state => state.historyEvents.sort((a, b) => new Date(a.date) - new Date(b.date)).reverse()[0],
    packagingGroup: state => (brand, unit) => state.segments.find(p => p.brand === brand && p.unit === unit),
    packagingGroups: state => state.packagingGroups,
    product: state => (id, europeanArticleNumber) => state.products.find(p => p.id === id && p.europeanArticleNumber === europeanArticleNumber),
    products: state => state.products,
    segment: state => id => state.segments.find(s => s.id === id),
    segments: state => state.segments,
    shelf: state => id => state.shelves.find(s => s.id === id),
    shelves: state => state.shelves,
    shelfPackagingGroup: state => (shelfId, packagingGroupBrand, packagingGroupUnit) => state.shelfPackagingGroup.find(s => s.shelfId === shelfId && s.packagingGroupBrand === packagingGroupBrand && s.packagingGroupUnit === packagingGroupUnit),
    shelfPackagingGroups: state => state.shelfPackagingGroup
  },
  mutations: {
    // historyEvents
    // =============
    setHistoryEvents(state, { historyEvents }) {
      state.historyEvents = historyEvents
    },

    // packagingGroups
    // ===============
    setPackagingGroups(state, { packagingGroups }) {
      state.packagingGroups = packagingGroups
    },

    // products
    // ========
    setProducts(state, { products }) {
      state.products = products
    },

    // segments
    // ========
    createSegment(state, { segment }) {
      state.segments.push(segment)
    },
    deleteSegment(state, { id }) {
      const index = state.segments.findIndex(s => s.id === id)

      if (index !== -1) {
        state.segments.splice(index, 1)
      }
    },
    setSegments(state, { segments }) {
      state.segments = segments
    },
    updateSegment(state, { segment }) {
      const index = state.segments.findIndex(s => s.id === segment.id)

      Vue.set(state.segments, index, segment)
    },

    // shelves
    // =======
    createShelf(state, { shelf }) {
      state.shelves.push(shelf)
    },
    deleteShelf(state, { id }) {
      const index = state.shelves.findIndex(s => s.id === id)

      if (index !== -1) {
        state.shelves.splice(index, 1)
      }
    },
    setShelves(state, { shelves }) {
      state.shelves = shelves
    },
    updateShelf(state, { shelf }) {
      const index = state.shelves.findIndex(s => s.id === shelf.id)

      Vue.set(state.shelves, index, shelf)
    },

    // shelfPackagingGroup
    // ===================
    createShelfPackagingGroup(state, { shelfPackagingGroup }) {
      state.shelfPackagingGroups.push(shelfPackagingGroup)

      const shelf = state.shelves.find(s => s.id === shelfPackagingGroup.shelfId)

      if (shelf == null) {
        return
      }

      shelf.packagingGroups.push({
        brand: shelfPackagingGroup.packagingGroupBrand,
        unit: shelfPackagingGroup.packagingGroupUnit
      })
    },
    deleteShelfPackagingGroup(state, { shelfId, packagingGroupBrand, packagingGroupUnit }) {
      const index = state.shelfPackagingGroups.findIndex(s => s.shelfId === shelfId && s.packagingGroupBrand === packagingGroupBrand && s.packagingGroupUnit === packagingGroupUnit)

      if (index !== -1) {
        state.shelfPackagingGroups.splice(index, 1)
      }

      const shelf = state.shelves.find(s => s.id === shelfId)

      if (shelf == null) {
        return
      }

      const shelfPackagingGroupIndex = shelf.packagingGroups.findIndex(p => p.brand === packagingGroupBrand && p.unit === packagingGroupUnit)

      if (shelfPackagingGroupIndex !== -1) {
        shelf.packagingGroups.splice(shelfPackagingGroupIndex, 1)
      }
    },
    setShelfPackagingGroups(state, { shelfPackagingGroup }) {
      state.products = shelfPackagingGroup
    },
    updateShelfPackagingGroup(state, { shelfPackagingGroup }) {
      const index = state.shelfPackagingGroups.findIndex(s => s.shelfId === shelfPackagingGroup.shelfId && s.packagingGroupBrand === shelfPackagingGroup.packagingGroupBrand && s.packagingGroupUnit === shelfPackagingGroup.packagingGroupUnit)

      Vue.set(state.shelfPackagingGroups, index, shelfPackagingGroup)
    }
  },
  namespaced: true,
  state: {
    historyEvents: [],
    packagingGroups: [],
    products: [],
    segments: [],
    shelves: [],
    shelfPackagingGroups: []
  }
}