<template>
  <div>
    <v-menu offset-y
            max-width="320"
            v-if="isAuthenticated">
      <template v-slot:activator="{ on }">
        <v-btn v-bind:icon="$vuetify.breakpoint.smAndDown"
               v-bind:text="$vuetify.breakpoint.mdAndUp"
               v-on="on">
          <v-icon v-bind:color="color"
                  v-bind:left="$vuetify.breakpoint.mdAndUp">
            mdi-account
          </v-icon>
          <span class="hidden-sm-and-down text-truncate">{{ displayName }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img v-bind:src="picture">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <slot />

        <v-list-item v-on:click="$emit('sign-out')">
          <v-list-item-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="deep-orange--text">{{ $t('signOut') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn v-bind:icon="$vuetify.breakpoint.smAndDown"
           v-bind:text="$vuetify.breakpoint.mdAndUp"
           v-else
           v-on:click="$emit('sign-in')">
      <v-icon v-bind:color="color"
              v-bind:left="$vuetify.breakpoint.mdAndUp">
        mdi-login
      </v-icon>
      <span class="hidden-sm-and-down">{{ $t('signIn') }}</span>
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'AccountBlock',
    props: {
      color: String,
      displayName: String,
      email: String,
      isAuthenticated: Boolean,
      picture: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "signIn": "Anmelden",
      "signOut": "Abmelden"
    },
    "en": {
      "signIn": "Sign in",
      "signOut": "Sign out"
    }
  }
</i18n>