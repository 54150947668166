<template>
  <div>
    <v-row>
      <v-col cols="6"
             v-bind:key="shelf.id"
             v-for="shelf in topShelves">
        <shelf-card v-bind:shelf="shelf" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3"
             v-bind:key="shelf.id"
             v-for="shelf in remainingShelves">
        <shelf-card small
                    v-bind:shelf="shelf" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ShelfCard from '@/components/ShelfCard'

  export default {
    components: {
      ShelfCard
    },
    computed: {
      filteredShelves() {
        return this.shelves.filter(s => s.state === 'full')
      },
      remainingShelves() {
        if (this.filteredShelves.length <= 4) {
          return []
        }

        return this.filteredShelves.slice(4, this.filteredShelves.length)
      },
      topShelves() {
        return this.filteredShelves.slice(0, 4)
      }
    },
    name: 'SegmentDisplay',
    props: {
      segment: Object,
      shelves: Array
    }
  }
</script>

<style scoped>
  .high-font-size {
    font-size: 10rem !important;
    line-height: 10rem;
  }
</style>