<template>
  <v-list class="py-0"
          dark>
    <v-list-item class="primary"
                 exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('back') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item exact
                 v-bind:to="{ name: 'administration-dashboard' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('dashboard') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-products' }">
      <v-list-item-icon>
        <v-icon>mdi-bottle-wine</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('products') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-segments' }">
      <v-list-item-icon>
        <v-icon>mdi-monitor</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('segments') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-shelves' }">
      <v-list-item-icon>
        <v-icon>mdi-tray</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('shelves') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-history-events' }">
      <v-list-item-icon>
        <v-icon>mdi-history</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('events') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'AdministrationNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "dashboard": "Dashboard",
      "events": "Ereignisse",
      "products": "Artikel",
      "segments": "Segmente",
      "shelves": "Fächer"
    },
    "en": {
      "back": "Back",
      "dashboard": "Dashboard",
      "events": "Events",
      "products": "Products",
      "segments": "Segments",
      "shelves": "Shelves"
    }
  }
</i18n>