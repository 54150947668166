var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"clearable":"","flat":"","hide-details":"","prepend-icon":"mdi-magnify","solo":"","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"clearable":"","flat":"","hide-details":"","multiple":"","prepend-icon":"mdi-monitor","solo":"","items":_vm.segmentItems,"label":_vm.$t('segment')},model:{value:(_vm.segmentFilter),callback:function ($$v) {_vm.segmentFilter=$$v},expression:"segmentFilter"}})],1)],1)]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('shelves')))])],1),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] },"headers":_vm.headers,"items":_vm.filteredShelves,"items-per-page":50,"loading":_vm.loading,"options":{ sortBy: [ 'id' ] }},on:{"click:row":_vm.goToItem},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"360"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-package")])],1)]}}],null,true)},[_c('shelf-packaging-groups-menu',{attrs:{"shelf-packaging-groups":item.packagingGroups},on:{"add-packaging-group":function($event){return _vm.$emit('add-packaging-group', item.id, $event)},"delete-packaging-group":function($event){return _vm.$emit('delete-packaging-group', item.id, $event)}}})],1)]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getStateColor(item.state)}},[_vm._v("mdi-circle")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }