<template>
  <div>
    <top-header v-bind:title="$t('events')">
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-bind:disabled="loading && !hasRole('SagasserSortScanAdministrator')"
                       v-on:click="exportHistoryEvents">
            <v-list-item-title>{{ $t('export') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <history-event-table-card v-bind:loading="loading"
                                v-bind:history-events="filteredHistoryEvents" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HistoryEventTableCard from '@/components/administration/HistoryEventTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      HistoryEventTableCard,
      TopHeader
    },
    computed: {
      filteredHistoryEvents() {
        var shelfTitle = ean => this.shelves.find(s => s.emptiedCode === ean || s.filledCode === ean)?.title

        return this.historyEvents.map(h => ({
          ...h,
          shelf: shelfTitle(h.data)
        }))
      },
      ...mapGetters({
        hasRole: 'auth/hasRole',
        historyEvents: 'base/historyEvents',
        shelves: 'base/shelves'
      })
    },
    async created() {
      this.loading = true

      await this.loadHistoryEvents()
      await this.loadShelves({ forDisplay: false })

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      exportHistoryEvents() {
        var csv = '"id";"date";"type";"format";"data";"shelf"\n'

        csv += this.filteredHistoryEvents.map(f => `"${f.id}";"${this.$options.filters.formatDate(f.date, 'LLL')}";"${f.type}";"${f.format}";"${f.data}";"${f.shelf}"`).join('\n')

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)
      },
      ...mapActions({
        loadHistoryEvents: 'base/loadHistoryEvents',
        loadShelves: 'base/loadShelves'
      })
    },
    name: 'HistoryEvents'
  }
</script>

<i18n>
  {
    "de": {
      "events": "Ereignisse",
      "export": "Exportieren"
    },
    "en": {
      "events": "Events",
      "export": "Export"
    }
  }
</i18n>