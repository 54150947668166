<template>
  <div>
    <top-header v-bind:title="$t('newSegment')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-segments' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="saveContract" />
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <segment-details-card editable-id
                                ref="segmentDetailsCard"
                                v-bind:segment="segment" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import SegmentDetailsCard from '@/components/administration/SegmentDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      SegmentDetailsCard,
      TopHeader
    },
    data: () => ({
      segment: {}
    }),
    methods: {
      async saveContract() {
        if (!this.$refs.segmentDetailsCard.validate()) {
          return
        }

        await this.createSegment({ segment: this.segment })

        this.$router.push({ name: 'administration-segments' })
      },
      ...mapActions({
        createSegment: 'base/createSegment'
      })
    },
    name: 'SegmentAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newSegment": "Neues Segment",
      "save": "Speichern"
    },
    "en": {
      "cancel": "Cancel",
      "newSegment": "New segment",
      "save": "Save"
    }
  }
</i18n>