<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field filled
                            hide-details="auto"
                            v-bind:disabled="!editableId"
                            v-bind:label="$t('id')"
                            v-bind:rules="[ rules.required ]"
                            v-model="shelf.id" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field filled
                            hide-details="auto"
                            v-bind:label="$t('title')"
                            v-bind:rules="[ rules.required ]"
                            v-model="shelf.title" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field append-icon="mdi-barcode-scan"
                            filled
                            hide-details="auto"
                            v-bind:label="$t('emptiedCode')"
                            v-bind:rules="[ rules.required ]"
                            v-model="shelf.emptiedCode"
                            v-on:click:append="$emit('insert-emptied-code')" />
              <ean13-display class="mt-4"
                             v-bind:code="shelf.emptiedCode" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field append-icon="mdi-barcode-scan"
                            filled
                            hide-details="auto"
                            v-bind:label="$t('filledCode')"
                            v-bind:rules="[ rules.required ]"
                            v-model="shelf.filledCode"
                            v-on:click:append="$emit('insert-filled-code')" />
              <ean13-display class="mt-4"
                             v-bind:code="shelf.filledCode" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select filled
                        hide-details="auto"
                        v-bind:items="segmentItems"
                        v-bind:label="$t('segment')"
                        v-bind:rules="[ rules.required ]"
                        v-model="shelf.segmentId" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Ean13Display from '@/components/Ean13Display.vue'

  export default {
    components: {
      Ean13Display
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      segmentItems() {
        return this.segments.map(s => ({
          text: s.title,
          value: s.id
        }))
      },
      ...mapGetters({
        segments: 'base/segments'
      })
    },
    name: 'ShelfDetailsCard',
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    props: {
      editableId: {
        type: Boolean,
        default: false
      },
      shelf: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "emptiedCode": "Leer-Code",
      "filledCode": "Voll-Code",
      "details": "Details",
      "id": "Nr.",
      "segment": "Segment",
      "title": "Bezeichnung"
    },
    "en": {
      "emptiedCode": "Emptied code",
      "filledCode": "Filled code",
      "details": "Details",
      "id": "ID",
      "segment": "Segment",
      "title": "Title"
    }
  }
</i18n>