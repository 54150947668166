<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisShelfIrrevocably')"
                    v-bind:title="$t('deleteShelf')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="shelf | defaultPropertyValue('title', $t('shelf'))">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-shelves' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                     v-bind:label="$t('save')"
                     v-on:click="updateShelf" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                       v-on:click="deleteShelf">
            <v-list-item-title v-bind:class="hasRole('SagasserSortScanAdministrator') ? 'deep-orange--text' : null">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <shelf-details-card ref="shelfDetailsCard"
                              v-bind:shelf="shelf"
                              v-if="shelf != null"
                              v-on:insert-emptied-code="insertEmptiedCode"
                              v-on:insert-filled-code="insertFilledCode" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <shelf-packaging-groups-card v-bind:shelf-packaging-groups="shelf.packagingGroups"
                                       v-if="shelf != null"
                                       v-on:add-packaging-group="addPackagingGroup"
                                       v-on:delete-packaging-group="deletePackagingGroup" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ShelfDetailsCard from '@/components/administration/ShelfDetailsCard.vue'
  import ShelfPackagingGroupsCard from '@/components/administration/ShelfPackagingGroupsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = to.params.id

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      HeaderButton,
      ShelfDetailsCard,
      ShelfPackagingGroupsCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        getShelf: 'base/shelf',
        hasRole: 'auth/hasRole',
        lastHistoryEvent: 'base/lastHistoryEvent'
      })
    },
    async created() {
      const id = this.$route.params.id

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      loading: false,
      shelf: null,
      shelfPackagingGroups: [],
      validationSnackbar: false
    }),
    methods: {
      async addPackagingGroup(packagingGroup) {
        // TODO: Check if exists

        this.createShelfPackagingGroup({ shelfPackagingGroup: {
          shelfId: this.shelf.id,
          packagingGroupBrand: packagingGroup.brand,
          packagingGroupUnit:
          packagingGroup.unit
        }})
      },
      async deletePackagingGroup(packagingGroup) {
        this.deleteShelfPackagingGroup({
          shelfId: this.shelf.id,
          packagingGroupBrand: packagingGroup.brand,
          packagingGroupUnit:
          packagingGroup.unit
        })
      },
      async deleteShelf() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteShelf({ id: this.shelf.id })

          this.loading = false

          this.$router.push({ name: 'administration-shelves' })
        })
      },
      async get(id) {
        this.loading = true

        await this.loadPackagingGroups()
        await this.loadProducts()
        await this.loadSegments()
        await this.loadShelves({ forDisplay: false })

        this.loading = false

        this.shelf = Object.assign({}, this.getShelf(id))
      },
      async insertEmptiedCode() {
        await this.loadHistoryEvents()

        this.shelf.emptiedCode = this.lastHistoryEvent?.data ?? ''
      },
      async insertFilledCode() {
        await this.loadHistoryEvents()

        this.shelf.filledCode = this.lastHistoryEvent?.data ?? ''
      },
      async updateShelf() {
        if (!this.$refs.shelfDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateShelf({ shelf: this.shelf })

        this.loading = false

        this.$router.push({ name: 'administration-shelves' })
      },
      ...mapActions({
        createShelfPackagingGroup: 'base/createShelfPackagingGroup',
        deleteShelfPackagingGroup: 'base/deleteShelfPackagingGroup',
        doDeleteShelf: 'base/deleteShelf',
        doUpdateShelf: 'base/updateShelf',
        loadHistoryEvents: 'base/loadHistoryEvents',
        loadPackagingGroups: 'base/loadPackagingGroups',
        loadProducts: 'base/loadProducts',
        loadSegments: 'base/loadSegments',
        loadShelves: 'base/loadShelves'
      })
    },
    name: 'Shelf'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisShelfIrrevocably": "Sind Sie sicher, dass Sie dieses Fach unwiderruflich löschen möchten?",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteShelf": "Fach löschen",
      "save": "Speichern",
      "shelf": "Fach",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisShelfIrrevocably": "Are you sure you want to delete this shelf irrevocably?",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteShelf": "Delete shelf",
      "save": "Save",
      "shelf": "Shelf",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>