import { HubConnectionBuilder } from '@microsoft/signalr'

import settings from './settings'
import store from './store'

var connection = new HubConnectionBuilder()
  .withAutomaticReconnect()
  .withUrl(`${settings.hubUri}/notificationhub`)
  .build()

connection.on('BroadcastNotification', async notification => {
  const forDisplay = !store.getters['auth/hasRole']('SagasserSortScanAdministrator') && !store.getters['auth/hasRole']('SagasserSortScanSupervisor')

  if (notification === 'shelf-changed') {
    await store.dispatch('base/loadShelves', { forDisplay })
  } else if (notification === 'reboot') {
    location.reload(true)
  }
})

connection.start().then(async () => {

}).catch(err => {
  return console.error(err.toString())
})