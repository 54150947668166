<template>
  <v-card tile
          hover
          v-bind:to="to">
    <v-card-title>
      <v-icon color="primary"
              large
              left>
        {{ icon }}
      </v-icon>
      <span class="font-weight-regular">{{ title }}</span>
    </v-card-title>
  </v-card>
</template>

<script>
  export default {
    name: 'IconDashboardCard',
    props: {
      icon: String,
      title: String,
      to: Object
    }
  }
</script>