import SegmentMonitor from '@/views/SegmentMonitor.vue'

export default [
  {
    components: {
      default: SegmentMonitor
    },
    name: 'segment-monitor',
    path: '/segments/:id'
  },
  {
    components: {
      default: SegmentMonitor,
    },
    name: 'home',
    path: '/'
  }
]