<template>
  <div>
    <svg v-show="!error" ref="barcode"></svg>
  </div>
</template>

<script>
  import JsBarcode from 'jsbarcode'

  export default {
    data: () => ({
      error: false
    }),
    methods: {
      generate() {
        try {
          JsBarcode(this.$refs.barcode, this.code, {
            format: 'EAN13'
          })
        } catch {
          this.error = true
        }
      }
    },
    mounted() {
      this.generate()
    },
    name: 'Ean13Display',
    props: {
      code: String
    },
    watch: {
      code() {
        this.generate()
      }
    }
  }
</script>