import moment from 'moment'
import Vue from 'vue'

import i18n from './plugins/i18n'

Vue.filter('defaultValue', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue
  }

  return value
})

Vue.filter('defaultPropertyValue', (value, property, defaultValue) => {
  if (value == null) {
    return defaultValue
  }

  if (value[property] == null || value[property].length === 0) {
    return defaultValue
  }

  return value[property]
})

Vue.filter('formatDate', (value, format, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).format(format ?? 'LL')
})

Vue.filter('fromNow', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).fromNow()
})