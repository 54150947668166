<template>
  <div>
    <top-header v-bind:title="$t('newShelf')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-shelves' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="saveShelf" />
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <shelf-details-card editable-id
                              ref="shelfDetailsCard"
                              v-bind:shelf="shelf"
                              v-on:insert-emptied-code="insertEmptiedCode"
                              v-on:insert-filled-code="insertFilledCode" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ShelfDetailsCard from '@/components/administration/ShelfDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      ShelfDetailsCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        lastHistoryEvent: 'base/lastHistoryEvent'
      })
    },
    async created() {
      await this.loadSegments()
    },
    data: () => ({
      shelf: {
        emptiedCode: '',
        filledCode: '',
        state: 'empty'
      }
    }),
    methods: {
      async insertEmptiedCode() {
        await this.loadHistoryEvents()

        this.shelf.emptiedCode = this.lastHistoryEvent?.data ?? ''
      },
      async insertFilledCode() {
        await this.loadHistoryEvents()

        this.shelf.filledCode = this.lastHistoryEvent?.data ?? ''
      },
      async saveShelf() {
        if (!this.$refs.shelfDetailsCard.validate()) {
          return
        }

        await this.createShelf({ shelf: this.shelf })

        this.$router.push({ name: 'administration-shelves' })
      },
      ...mapActions({
        createShelf: 'base/createShelf',
        loadHistoryEvents: 'base/loadHistoryEvents',
        loadSegments: 'base/loadSegments'
      })
    },
    name: 'ShelfAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newShelf": "Neues Fach",
      "save": "Speichern"
    },
    "en": {
      "cancel": "Cancel",
      "newShelf": "New shelf",
      "save": "Save"
    }
  }
</i18n>