<template>
  <v-card outlined>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('products') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table item-key="key"
                  v-bind:footer-props="{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredProducts"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'id', 'europeanArticleNumber' ] }">
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    computed: {
      filteredProducts() {
        let filteredProducts = this.products.map(p => ({
          key: `${p.id}-${p.europeanArticleNumber}`,
          ...p
        }))

        if (this.search != null && this.search.length !== 0) {
          filteredProducts = filteredProducts.filter(f => `${f.id} ${f.europeanArticleNumber} ${f.title}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        return filteredProducts
      },
      headers() {
        return [
          {
            text: this.$t('id'),
            value: 'id'
          },
          {
            text: this.$t('ean'),
            value: 'europeanArticleNumber'
          },
          {
            text: this.$t('title'),
            value: 'title'
          },
          {
            text: this.$t('brand'),
            value: 'packagingGroupBrand'
          },
          {
            text: this.$t('packagingUnit'),
            value: 'packagingGroupUnit'
          }
        ]
      }
    },
    data: () => ({
      search: null
    }),
    name: 'ProductTableCard',
    props: {
      loading: Boolean,
      products: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "brand": "Marke",
      "ean": "EAN",
      "id": "Nr.",
      "products": "Artikel",
      "search": "Suche",
      "title": "Bezeichnung",
      "packagingUnit": "Verpackungseinheit"
    },
    "en": {
      "brand": "Brand",
      "ean": "EAN",
      "id": "ID",
      "products": "Products",
      "search": "Search",
      "title": "Title",
      "packagingUnit": "Packaging unit"
    }
  }
</i18n>