<template>
  <div>
    <v-list>
      <v-autocomplete autofocus
                      auto-select-first
                      flat
                      hide-details="auto"
                      prepend-inner-icon="mdi-plus"
                      solo
                      v-bind:items="packagingGroupItems"
                      v-bind:label="$t('addPackagingGroup')"
                      v-model="newPackagingGroup"
                      v-on:change="addPackagingGroup" />
      <v-divider v-if="shelfPackagingGroups.length > 0" />
      <v-list-item two-line
                    v-bind:key="index"
                    v-for="(shelfPackagingGroup, index) in shelfPackagingGroups">
        <v-list-item-content>
          <v-list-item-title>{{ shelfPackagingGroup.brand }}</v-list-item-title>
          <v-list-item-subtitle>{{ shelfPackagingGroup.unit }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="deep-orange"
                    v-on:click="deletePackagingGroup(shelfPackagingGroup)">
              mdi-close-circle
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      packagingGroupItems() {
        return this.packagingGroups.filter(p => !this.shelfPackagingGroups.some(s => p.brand === s.brand && p.unit === s.unit))
          .map(p => ({
            text: `${p.brand} ${p.unit}`,
            value: p
          }))
      },
      ...mapGetters({
        packagingGroups: 'base/packagingGroups'
      })
    },
    data: () => ({
      newPackagingGroup: null
    }),
    name: 'ShelfPackagingGroupsMenu',
    methods: {
      addPackagingGroup(packagingGroup) {
        if (packagingGroup == null) {
          return
        }

        this.$emit('add-packaging-group', packagingGroup)
      },
      deletePackagingGroup(packagingGroup) {
        this.$emit('delete-packaging-group', packagingGroup)
      }
    },
    props: {
      shelfPackagingGroups: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "addPackagingGroup": "Verpackungsgruppe hinzufügen"
    },
    "en": {
      "addPackagingGroup": "Add packaging group"
    }
  }
</i18n>