<template>
  <v-card outlined>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('events') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredHistoryEvents"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading">
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | formatDate('LLL' )}}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ $t(historyEventTypeLabel(item.type)) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredHistoryEvents() {
        let filteredHistoryEvents = this.historyEvents

        if (this.search != null && this.search.length !== 0) {
          filteredHistoryEvents = filteredHistoryEvents.filter(f => `${f.id} ${f.data} ${f.type}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        return filteredHistoryEvents
      },
      headers() {
        return [
          {
            text: this.$t('id'),
            value: 'id'
          },
          {
            text: this.$t('date'),
            value: 'date'
          },
          {
            text: this.$t('type'),
            value: 'type'
          },
          {
            text: this.$t('ean'),
            value: 'data'
          },
          {
            text: this.$t('shelf'),
            value: 'shelf'
          }
        ]
      },
      ...mapGetters({
        historyEventTypeLabel: 'local/historyEventTypeLabel'
      })
    },
    data: () => ({
      search: null
    }),
    name: 'HistoryEventTableCard',
    props: {
      loading: Boolean,
      historyEvents: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "date": "Datum",
      "ean": "EAN",
      "events": "Ereignisse",
      "id": "Nr.",
      "search": "Suche",
      "shelf": "Fach",
      "type": "Art"
    },
    "en": {
      "date": "Date",
      "ean": "EAN",
      "events": "Events",
      "id": "ID",
      "search": "Search",
      "shelf": "Shelf",
      "type": "Type"
    }
  }
</i18n>