<template>
  <div>
    <top-header v-bind:title="$t('dashboard')">
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                       v-on:click="rebootAllClients">
            <v-list-item-title v-bind:class="hasRole('SagasserSortScanAdministrator') ? 'deep-orange--text' : null">{{ $t('rebootAllClients') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <icon-dashboard-card icon="mdi-bottle-wine"
                               v-bind:title="$t('products')"
                               v-bind:to="{ name: 'administration-products' }" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <icon-dashboard-card icon="mdi-monitor"
                               v-bind:title="$t('segments')"
                               v-bind:to="{ name: 'administration-segments' }" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               lg="6">
          <icon-dashboard-card icon="mdi-tray"
                               v-bind:title="$t('shelves')"
                               v-bind:to="{ name: 'administration-shelves' }" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <icon-dashboard-card icon="mdi-history"
                               v-bind:title="$t('events')"
                               v-bind:to="{ name: 'administration-history-events' }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import IconDashboardCard from '@/components/common/IconDashboardCard.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      IconDashboardCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        hasRole: 'auth/hasRole'
      })
    },
    methods: {
      rebootAllClients() {
        this.maintenanceReboot()
      },
      ...mapActions({
        maintenanceReboot: 'base/maintenanceReboot'
      })
    },
    name: 'Dashboard'
  }
</script>

<i18n>
  {
    "de": {
      "dashboard": "Dashboard",
      "events": "Ereignisse",
      "products": "Produkte",
      "rebootAllClients": "Alle Clients neustarten",
      "segments": "Segmente",
      "shelves": "Fächer"
    },
    "en": {
      "dashboard": "Dashboard",
      "events": "Events",
      "products": "Products",
      "rebootAllClients": "Reboot all clients",
      "segments": "Segments",
      "shelves": "Shelves"
    }
  }
</i18n>