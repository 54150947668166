<template>
  <div>
    <top-header v-bind:title="$t('shelves')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:disabled="!hasRole('SagasserSortScanAdministrator')"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'administration-shelf-add' }"  />
    </top-header>
    <v-container>
      <shelf-table-card item-route-name="administration-shelf"
                        v-bind:loading="loading"
                        v-bind:shelves="shelves"
                        v-on:add-packaging-group="addPackagingGroup"
                        v-on:delete-packaging-group="deletePackagingGroup" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ShelfTableCard from '@/components/administration/ShelfTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      ShelfTableCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        hasRole: 'auth/hasRole',
        shelves: 'base/shelves'
      })
    },
    async created() {
      this.loading = true

      await this.loadPackagingGroups()
      await this.loadSegments()
      await this.loadShelves({ forDisplay: false })

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      async addPackagingGroup(id, packagingGroup) {
        // TODO: Check if exists

        this.createShelfPackagingGroup({ shelfPackagingGroup: {
          shelfId: id,
          packagingGroupBrand: packagingGroup.brand,
          packagingGroupUnit: packagingGroup.unit
        }})
      },
      async deletePackagingGroup(id, packagingGroup) {
        this.deleteShelfPackagingGroup({
          shelfId: id,
          packagingGroupBrand: packagingGroup.brand,
          packagingGroupUnit: packagingGroup.unit
        })
      },
      ...mapActions({
        createShelfPackagingGroup: 'base/createShelfPackagingGroup',
        deleteShelfPackagingGroup: 'base/deleteShelfPackagingGroup',
        loadPackagingGroups: 'base/loadPackagingGroups',
        loadSegments: 'base/loadSegments',
        loadShelves: 'base/loadShelves'
      })
    },
    name: 'Shelves'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "shelves": "Fächer"
    },
    "en": {
      "add": "Add",
      "shelves": "Shelves"
    }
  }
</i18n>