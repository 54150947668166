export default class PackagingGroupService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(packagingGroup, options) {
    const response = await fetch(`${this.baseUri}/packagingGroup`, {
      body: JSON.stringify(packagingGroup),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(brand, unit, options) {
    const response = await fetch(`${this.baseUri}/packagingGroup/${brand}/${encodeURIComponent(unit)}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(brand, unit, options) {
    try {
      const response = await fetch(`${this.baseUri}/packagingGroup/${brand}/${encodeURIComponent(unit)}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/packagingGroup`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(brand, unit, packagingGroup, options) {
    const response = await fetch(`${this.baseUri}/packagingGroup/${brand}/${encodeURIComponent(unit)}`, {
      body: JSON.stringify(packagingGroup),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }
}