import Dashboard from '@/views/administration/Dashboard.vue'
import HistoryEvents from '@/views/administration/HistoryEvents.vue'
import Products from '@/views/administration/Products.vue'
import Segment from '@/views/administration/Segment.vue'
import SegmentAdd from '@/views/administration/SegmentAdd.vue'
import Segments from '@/views/administration/Segments.vue'
import Shelf from '@/views/administration/Shelf.vue'
import ShelfAdd from '@/views/administration/ShelfAdd.vue'
import Shelves from '@/views/administration/Shelves.vue'
import AdministrationNavigation from '@/views/administration/navigation/AdministrationNavigation.vue'

export default [
  {
    components: {
      default: Dashboard,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-dashboard',
    path: '/administration'
  },
  {
    components: {
      default: HistoryEvents,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-history-events',
    path: '/administration/history-events'
  },
  {
    components: {
      default: Products,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-products',
    path: '/administration/products'
  },
  {
    components: {
      default: SegmentAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserSortScanAdministrator'),
      hasNavigation: true
    },
    name: 'administration-segment-add',
    path: '/administration/segments/add'
  },
  {
    components: {
      default: Segment,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-segment',
    path: '/administration/segments/:id'
  },
  {
    components: {
      default: Segments,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-segments',
    path: '/administration/segments'
  },
  {
    components: {
      default: ShelfAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserSortScanAdministrator'),
      hasNavigation: true
    },
    name: 'administration-shelf-add',
    path: '/administration/shelves/add'
  },
  {
    components: {
      default: Shelf,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-shelf',
    path: '/administration/shelves/:id'
  },
  {
    components: {
      default: Shelves,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserSortScanAdministrator') || hasRole('SagasserSortScanSupervisor')),
      hasNavigation: true
    },
    name: 'administration-shelves',
    path: '/administration/shelves'
  }
]