<template>
  <v-card outlined>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details
                            multiple
                            prepend-icon="mdi-monitor"
                            solo
                            v-bind:items="segmentItems"
                            v-bind:label="$t('segment')"
                            v-model="segmentFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('shelves') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredShelves"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'id' ] }"
                  v-on:click:row="goToItem">
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu v-bind:close-on-content-click="false" min-width="360">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark
                  icon
                  v-bind="attrs"
                  v-on="on">
              <v-icon>mdi-package</v-icon>
            </v-btn>
          </template>
          <shelf-packaging-groups-menu v-bind:shelf-packaging-groups="item.packagingGroups"
                                       v-on:add-packaging-group="$emit('add-packaging-group', item.id, $event)"
                                       v-on:delete-packaging-group="$emit('delete-packaging-group', item.id, $event)" />
        </v-menu>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-icon v-bind:color="getStateColor(item.state)">mdi-circle</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShelfPackagingGroupsMenu from '@/components/administration/ShelfPackagingGroupsMenu.vue'

  export default {
    components: {
      ShelfPackagingGroupsMenu
    },
    computed: {
      filteredShelves() {
        let filteredShelves = this.shelves

        if (this.search != null && this.search.length !== 0) {
          filteredShelves = filteredShelves.filter(f => `${f.id} ${f.title} ${f.emptiedCode} ${f.filledCode}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.segmentFilter != null && this.segmentFilter.length !== 0) {
          filteredShelves = filteredShelves.filter(f => this.segmentFilter.includes(f.segmentId))
        }

        return filteredShelves
      },
      headers() {
        return [
          {
            text: this.$t('state'),
            value: 'state',
            width: 96
          },
          {
            text: this.$t('id'),
            value: 'id'
          },
          {
            text: this.$t('title'),
            value: 'title'
          },
          {
            text: this.$t('emptiedCode'),
            value: 'emptiedCode'
          },
          {
            text: this.$t('filledCode'),
            value: 'filledCode'
          },
          {
            text: this.$t('segment'),
            value: 'segment.title'
          },
          {
            text: null,
            value: 'actions',
            width: 96
          }
        ]
      },
      segmentItems() {
        return this.segments.map(s => ({
          text: s.title,
          value: s.id
        }))
      },
      ...mapGetters({
        segments: 'base/segments'
      })
    },
    data: () => ({
      search: null,
      segmentFilter: null
    }),
    methods: {
      getStateColor(state) {
        if (state === 'empty') {
          return 'light-green'
        } else {
          return 'deep-orange'
        }
      },
      goToItem(item) {
          this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'ShelfTableCard',
    props: {
      itemRouteName: String,
      loading: Boolean,
      shelves: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "emptiedCode": "Leer-Code",
      "filledCode": "Voll-Code",
      "id": "Nr.",
      "search": "Suche",
      "segment": "Segment",
      "shelves": "Fächer",
      "state": "Status",
      "title": "Bezeichnung"
    },
    "en": {
      "emptiedCode": "Emptied code",
      "filledCode": "Filled code",
      "id": "ID",
      "search": "Search",
      "segment": "Segment",
      "shelves": "Shelves",
      "state": "State",
      "title": "Title"
    }
  }
</i18n>