<template>
  <div>
    <v-menu offset-y
            max-width="320">
      <template v-slot:activator="{ on }">
        <v-btn v-bind:icon="$vuetify.breakpoint.smAndDown"
               v-bind:text="$vuetify.breakpoint.mdAndUp"
               v-on="on">
          <v-icon v-bind:color="color">mdi-translate</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item two-line
                     v-bind:key="locale.id"
                     v-for="locale in locales"
                     v-on:click="$emit('set-locale', { localeId: locale.id })">
          <v-list-item-content>
            <v-list-item-title>{{ locale.nativeTitle }}</v-list-item-title>
            <v-list-item-subtitle>{{ locale.title }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'LocaleBlock',
    props: {
      color: String,
      locales: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "language": "Sprache"
    },
    "en": {
      "language": "Language"
    }
  }
</i18n>