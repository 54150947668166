<template>
  <v-card outlined>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('segments') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredSegments"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'id' ] }"
                  v-on:click:row="goToItem">
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    computed: {
      filteredSegments() {
        let filteredSegments = this.segments

        if (this.search != null && this.search.length !== 0) {
          filteredSegments = filteredSegments.filter(f => `${f.id} ${f.title}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        return filteredSegments
      },
      headers() {
        return [
          {
            text: this.$t('id'),
            value: 'id'
          },
          {
            text: this.$t('title'),
            value: 'title'
          }
        ]
      }
    },
    data: () => ({
      search: null
    }),
    methods: {
      goToItem(item) {
          this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'SegmentTableCard',
    props: {
      itemRouteName: String,
      loading: Boolean,
      segments: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "id": "Nr.",
      "search": "Suche",
      "segments": "Segmente",
      "title": "Bezeichnung"
    },
    "en": {
      "id": "ID",
      "search": "Search",
      "segments": "Segments",
      "title": "Title"
    }
  }
</i18n>